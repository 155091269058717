<template>
  <cascader
    v-model="data"
    style="width:100%;"
    :options="address()"
    :props="{ expandTrigger: 'hover' }"
    @change="addressChange"
  />
</template>

<script>
import { ref, watchEffect } from '@vue/composition-api'
import { Cascader } from 'element-ui'
import { address } from '@/utils/address'
export default {
  name: 'AddressSelect',

  components: {
    Cascader
  },
  props: {
    citys: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { emit } = context

    const data = ref([])

    watchEffect(() => {
      data.value = props.citys
    })

    const addressChange = (val) => {
      const prov = val[0]
      const city = val[1] || 0
      const area = val[2] || 0

      emit('change', {
        prov,
        city,
        area
      })
    }
    return {
      data,
      address,
      addressChange
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
