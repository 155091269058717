<!--
 * @Author: Neko
 * @Date: 2021-03-19 18:29:44
 * @LastEditTime: 2021-10-18 14:36:41
 * @LastEditors: Please set LastEditors
-->
<template>
  <el-form
    ref="form"
    :model="formData"
    class="address-form"
    :class="{ column: type === 'column' }"
    :inline="false"
    label-position="left"
    label-width="120px"
    :rules="!disabled ? ruler : {}"
  >
    <el-row :gutter="50">
      <el-col :span="type === 'column' ? 24 : 8">
        <el-form-item :label="$t('common.name') + '：'" prop="consignee">
          <el-input v-if="!disabled" v-model="formData.consignee" :maxlength="256" />
          <span v-else class="value">{{ formData.consignee }}</span>
        </el-form-item>
      </el-col>

      <el-col :span="type === 'column' ? 24 : 8">
        <el-form-item :label="$t('common.phone') + '：'" prop="mobile">
          <el-input v-if="!disabled" v-model="formData.mobile" :maxlength="50" />
          <span v-else class="value">{{ formData.mobile }}</span>
        </el-form-item>
      </el-col>

      <el-col :span="type === 'column' ? 24 : 8">
        <el-form-item :label="$t('order.country') + '：'" prop="countryId">
          <el-select v-if="!disabled" v-model="country" value-key="id" placeholder=" ">
            <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.country"
              :value="item"
            />
          </el-select>

          <span v-else class="value">{{ formData.countryName }}</span>
        </el-form-item>
      </el-col>

      <el-col :span="type === 'column' ? 24 : 8">
        <el-form-item :label="$t('order.stateRegion') + '：'" prop="region">
          <el-input v-if="!disabled" v-model="formData.region" :maxlength="256" />
          <span v-else class="value">{{ formData.region }}</span>
        </el-form-item>
      </el-col>

      <el-col :span="type === 'column' ? 24 : 8">
        <el-form-item :label="$t('order.postcode') + '：'" prop="postcode">
          <el-input v-if="!disabled" v-model="formData.postcode" :maxlength="256" />
          <span v-else class="value">{{ formData.postcode }}</span>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <el-form-item :label="$t('common.address') + '：'" prop="address">
          <el-input
            v-if="!disabled"
            v-model="formData.address"
            type="textarea"
            :placeholder="$t('common.Pleaseinputaddresshosenumber')"
            :maxlength="100"
            show-word-limit
          />

          <span v-else class="value">
            {{ formData.address }}
          </span>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col v-if="!disabled" :span="24" :class="{ 'text-center': type !== 'column', 'text-right': type === 'column' }">
        <el-checkbox v-if="type === 'column'" v-model="formData.defaultType" :true-label="1" :false-label="0">{{ this.$t('common.Setdefault') }}</el-checkbox>
        <el-button type="primary" class="save-button" @click="onClickSaveBtn">{{ this.$t('common.preservation') }}</el-button>
        <el-button v-if="type === 'column'" class="cancel-button" @click="onClickCancelBtn">{{ this.$t('order.cancel') }}</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, ref, watch, toRef } from '@vue/composition-api'
import { useRequest } from '@/utils/request.js'

export default defineComponent({
  emits: ['confirm'],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    data: {
      type: Object,
      default: () => ({})
    },

    type: {
      type: String,
      default: 'normal'
    },

    default: {
      type: Number,
      default: 0
    }
  },

  setup(props, { root, emit }) {
    const propsRef = toRefs(props)
    const propData = propsRef.data
    const $t = root.$jst
    const blur = { required: true, message: $t('order.Pleaseinput'), trigger: 'blur' }
    const change = { required: true, message: $t('order.Pleaseselect'), trigger: 'change' }

    const form = ref(null)

    const data = reactive({
      formData: {
        id: '',
        address: '',
        consignee: '',
        countryId: '',
        countryName: '',
        mobile: '',
        defaultType: props.default,
        postcode: '',
        iso: '',
        region: ''
      },

      ruler: {
        address: blur,
        consignee: blur,
        countryId: change,
        mobile: blur,
        postcode: blur,
        region: blur
      },

      country: {},

      countryList: []
    })

    watch(() => propData.value, res => {
      data.formData = {
        id: res.id || '',
        address: res.address || '',
        consignee: res.consignee || '',
        countryId: res.countryId || '',
        countryName: res.countryName || '',
        mobile: res.mobile || '',
        defaultType: res.defaultType || '',
        postcode: res.postcode || '',
        iso: res.iso || '',
        region: res.region || ''
      }

      data.country = {
        country: res.countryName,
        id: res.countryId
      }
    })

    watch(() => data.country, res => {
      data.formData.countryId = res.id
      data.formData.country = res.country
      data.formData.iso = res.iso
    })

    const { fetch } = useRequest('address/addressAdd', {
      data: computed(() => data.formData),

      immediate: false,

      onSuccess(res) {
        if (res) {
          resetFields()
        }
      }
    })

    const onClickSaveBtn = _ => {
      form.value.validate(isValid => {
        if (isValid) {
          if (data.formData.id) {
            addressUpdateFetch()
          } else {
            fetch()
          }
        }
      })
    }

    const addressId = ref('')

    const { data: addressDetail, fetch: addressDetailFetch } = useRequest('address/addressDetail', {
      data: computed(() => ({
        id: addressId.value
      })),

      immediate: false
    })

    const { data: addressUpadteResult, fetch: addressUpdateFetch } = useRequest('address/addressUpdate', {
      data: computed(() => data.formData),
      onSuccess(res) {
        if (res) {
          resetFields()
        }
      },
      immediate: false
    })

    useRequest('address/getAddressCountryList', {
      initialData: toRef(data, 'countryList')
    })

    const setForm = id => {
      addressId.value = id
      addressDetailFetch()
    }

    watch(() => addressDetail.value, res => {
      data.formData = {
        id: res.id || '',
        address: res.address || '',
        consignee: res.consignee || '',
        countryId: res.countryId || '',
        countryName: res.countryName || '',
        mobile: res.mobile || '',
        defaultType: res.defaultType,
        postcode: res.postcode || '',
        iso: res.iso || '',
        region: res.region || ''
      }

      data.country = {
        country: res.countryName,
        id: res.countryId,
        iso: res.iso
      }

      emit('confirm')
    })

    const resetFields = _ => {
      data.formData = {
        address: '',
        consignee: '',
        countryId: '',
        countryName: '',
        mobile: '',
        defaultType: '',
        postcode: '',
        iso: '',
        region: ''
      }

      data.country = {}

      form.value.resetFields()
      emit('confirm')
    }

    watch(() => addressUpadteResult.value, res => {
      if (res) {
        resetFields()
      }
    })

    // watch(() => result.value, res => {
    //   if (res) {
    //     resetFields()
    //   }
    // })

    const onClickCancelBtn = _ => {
      data.formData = {
        address: '',
        consignee: '',
        countryId: '',
        countryName: '',
        mobile: '',
        defaultType: 1,
        postcode: '',
        iso: '',
        region: ''
      }

      data.country = {}

      form.value.resetFields()
    }

    return {
      ...toRefs(data),
      form,

      onClickSaveBtn,
      onClickCancelBtn,
      setForm
    }
  }
})
</script>

<style lang="scss" scoped>
.address-form {
  padding: 24px 0 20px;

  ::v-deep .el-form-item__label {
    font-size: 14px;
    color: #666666;
  }

  ::v-deep .el-input__inner {
    height: 28px;
    line-height: 28px;
  }

  &.column ::v-deep .el-input__inner {
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
  }

  &.column ::v-deep .el-textarea__inner {
    border-radius: 4px;
  }

  &.column ::v-deep .el-form-item__label {
    line-height: 40px;
  }

  .value {
    font-size: 14px;
    color: #666;
  }

  .save-button {
    width: 110px;
    height: 28px;
    padding: 0;
    margin-top: 43px;
    border-radius: 14px;
    border-color: #034BAD;
    background: #034BAD;
    box-shadow: 0px 16px 40px 6px rgba(0, 73, 172, 0.17);
  }

  .cancel-button {
    color: #034BAD;
    background: #E5E9EE;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;

    .save-button, .cancel-button {
      width: 80px;
      height: 40px;
      border-radius: 20px;
      line-height: 40px;
      margin-left: 62px;
    }

    .cancel-button {
      line-height: 1;
      border-color: #034BAD;
      margin-left: 10px;
    }
  }
}
</style>
