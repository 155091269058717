<template>
  <el-dialog append-to-body :visible.sync="isShowDialog" :title="isEdit ? this.$t('common.Editshippingaddress') : this.$t('common.addshippingaddress') " @close="onCloseDialog">
    <el-form
      ref="editForm"
      class="address-form edit-form"
      label-width="120px"
      label-position="right"
      :model="addressForm"
      :rules="ruler"
    >
      <el-form-item :label="$t('common.ConsigneeName') + ':'" prop="consignee">
        <el-input v-model="addressForm.consignee" />
      </el-form-item>

      <el-form-item :label="$t('common.phonenumber') + ':'" prop="mobile">
        <el-input v-model="addressForm.mobile" />
      </el-form-item>

      <el-form-item :label="$t('common.province') + '/' + $t('common.city') + '/' + $t('common.area') + '：'" prop="prov">
        <address-select :citys="citys" @change="onAddressChange" />
      </el-form-item>

      <el-form-item :label="$t('order.province') + '：'" prop="address">
        <el-input v-model="addressForm.address" type="textarea" />
      </el-form-item>

      <el-form-item class="buttons">
        <el-button type="primary" class="button" @click="onUpdate">{{ this.$t('common.Submit') }}</el-button>
        <el-button class="button white" @click="onCloseDialog">{{ this.$t('order.cancel') }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { computed, ref, toRefs, reactive, watch } from '@vue/composition-api'
import AddressSelect from '@/components/Address/AddressSelect'
import { Message } from 'element-ui'
import { useRequest } from '@/utils/request.js'
import { validateMobile } from '@/utils'

export default {
  name: 'AddressDialog',
  components: {
    AddressSelect
  },
  emits: ['success'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    },
    test: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, context) {
    const { emit } = context
    const $t = context.root.$jst
    const editForm = ref(null)
    const isShowDialog = computed(({ get: () => { return props.visible }, set: (val) => { emit('update:visible', val) } }))

    const state = reactive({
      addressForm: {
        consignee: '',
        mobile: '',
        address: ''
      },
      citys: [],

      ruler: {
        consignee: { required: true, message: $t('order.Pleaseinput'), trigger: 'blur' },
        mobile: [{ required: true, message: $t('order.Pleaseinput'), trigger: 'blur' }, { validator: validateMobile, trigger: 'blur' }],
        address: { required: true, message: $t('order.Pleaseinput'), trigger: 'blur' },
        prov: { required: true, message: $t('order.Pleaseselect'), trigger: 'blur' }
      }
    })

    const { fetch: addressAdd } = useRequest('address/addressAdd', {
      data: computed(() => ({
        ...state.addressForm,
        ...state.citys
      })),
      isCustom: true,
      immediate: false,
      onSuccess: (res) => {
        Message({
          message: $t('common.Addreceivingaddresssuccessfully'),
          type: 'success'
        })
        state.addressForm = {}
        state.citys = []
        emit('success')
      }
    })

    const { fetch: addressDetail } = useRequest('address/addressDetail', {
      data: computed(() => ({
        id: props.id
      })),
      isCustom: true,
      immediate: false,
      onSuccess: (res) => {
        const { data } = res
        const { consignee, mobile, address, prov, city, area } = data
        const tempCity = []
        if (prov) {
          tempCity.push(prov)
        }
        if (city) {
          tempCity.push(city)
        }
        if (area) {
          tempCity.push(area)
        }
        state.citys = tempCity
        state.addressForm = {
          consignee,
          mobile,
          address,
          prov,
          city,
          area
        }
      }
    })

    const { fetch: addressUpdate } = useRequest('address/addressUpdate', {
      data: computed(() => ({
        id: props.id,
        ...state.addressForm
      })),
      isCustom: true,
      immediate: false,
      onSuccess: (res) => {
        Message({
          message: $t('common.modifiedaddressssuccessfully'),
          type: 'success'
        })
        state.addressForm = {}
        state.citys = []
        emit('success')
      }
    })

    watch(() => props.id, (val) => {
      if (val > 0) addressDetail()
    })

    const onUpdate = () => {
      if (!editForm.value) return
      editForm.value.validate(isValid => {
        if (isValid) {
          if (props.isEdit) {
            addressUpdate()
          } else {
            addressAdd()
          }

          emit('update:visible')
        }
      })
    }

    const onCloseDialog = () => {
      if (!editForm.value) return
      editForm.value.resetFields()

      emit('update:visible')
    }

    const onAddressChange = (val) => {
      state.addressForm = { ...state.addressForm, ...val }
    }

    return {
      ...toRefs(state),
      editForm,

      isShowDialog,
      onUpdate,
      onCloseDialog,
      onAddressChange
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
